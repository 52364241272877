@font-face {
    font-family: OpenSans;
    font-style: regular;
    src: url("./app/assets/open-sans/OpenSans-Regular.ttf");
  }
  
  @font-face {
    font-family: OpenSansBold;
    src: url("./app/assets/open-sans/OpenSans-Bold.ttf");
  }
  
  @font-face {
    font-family: OpenSansLight;
    src: url("./app/assets/open-sans/OpenSans-Light.ttf");
  }
  
  @font-face {
    font-family: OpenSansSemibold;
    src: url("./app/assets/open-sans/OpenSans-Semibold.ttf");
  }
  
  .default-font {
    font-family: OpenSans;
    font-style: regular;
  }
  
  .default-font-bold {
    font-family: OpenSansBold;
  }
  
  .default-font-light {
    font-family: OpenSansLight;
  }
  
  html {
    position: relative;
    min-height: 100%;
    font-family: OpenSans;
  }
  
  body {
    color: #fff;
    margin: 0 0 60px; // footer
  }
  
  .italic {
    font-style: italic;
  }
  
  .default-page {
    color: black;
    font-family: OpenSansLight;
    text-align: left;
  
    h1 {
      font-size: 37px;
      color: #454f8c;
      margin-bottom: 0px;
      text-align: left;
    }
  
    h2 {
      font-size: 22px;
      margin: 0px;
      color: black;
      text-align: left;
    }
  
    .divider {
      width: 100%;
      height: 10px;
      background-color: #f1f2f4;
      margin-bottom: 35px;
      margin-top: 35px;
    }
  
    a {
      text-decoration: underline;
      color: black;
      margin-bottom: 0px;
    }
  
    .col-md-7,
    .col-md-6,
    .col-md-8,
    .no-left-padding {
      padding-left: 0px;
    }
  
    .btn-pfm,
    .btn-pfm-large {
      margin-top: 35px;
      margin-bottom: 35px;
    }
  
    .container {
      margin-top: 10px;
    }
  }
  
  #tooltip {
    display: inline-table;
  }
  
  .theater-banner {
    background-image: url("./app/assets/theater-banner.png");
    height: 450px;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
  
    @media (max-width: 992px) {
      height: 300px;
    }
  
    @media (max-width: 700px) {
      height: 200px;
    }
  
    @media (max-width: 400px) {
      height: 150px;
    }
  }
  
  .btn-pfm {
    width: 200px;
    height: 48px;
    font-size: 18px;
    color: white;
    background-color: #6f50a1;
    font-family: OpenSansSemibold;
    letter-spacing: 3px;
    border-radius: 7px;
  }
  
  .btn-pfm-large {
    @extend .btn-pfm;
    display: inline-block;
    width: 207px;
    height: 41px;
    font-size: 18px;
  }
  
  .modal-content {
    background-color: #ebebeb;
  }
  
  @media (min-width: 768px) {
    .modal-dialog {
      width: 400px;
      margin-top: 400px;
      transform: translate(0, 50%);
    }
  }
  
  .no-left-padding {
    padding-left: 0px;
  }
  
  .error {
    color: #be0000;
    margin-top: 5px;
    font-family: OpenSansLight;
    font-size: 11px;
  }
  
  .success {
    color: #228b3c;
    margin-top: 5px;
    font-family: OpenSansLight;
    font-size: 11px;
  }
  
  label.question-label {
    margin: 0px;
    text-align: left;
    font-size: 13px;
    color: #9494b8;
    white-space: nowrap;
    display: inline-block;
    max-width: 100%;
  }
  
  .modal-dialog.browser-unsupported {
    color: #333;
  }
  
  .pfm-ng-hide {
    display: none;
  }
  
  button {
    outline: none !important;
  }
  
  .loader {
    position: relative;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    z-index: 10000;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #6f50a1;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  
  .button-loader {
    display: block;
    height: 30px;
    width: 30px;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    border: 4px solid #6f5081;
    border-top: 4px solid #f3f3f3;
  }
  
  @keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
    }
  
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 26px;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  }
  
  
  input:checked+.slider {
    background-color: #159B51;
  }
  
  
  input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  .copy {
    padding: 0 !important;
  }
  
  a.dismiss {
    display: none !important;
  }
  
  a.accordion-toggle {
    text-decoration: none !important;
  }
  
  /* Add tabset class to tabset component*/
  .tabset {
    display: flex;
    border: 1px solid #b2b2b2;
    border-radius: 4px !important;
    height: 40vh;
    min-height: 350px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
  }
  
  .tab-content {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .nav {
    background-color: #e3e3e3;
    width: 25%;
    min-width: 150px;
    font-size: 16px;
  }
  
  .nav-link {
    color: black;
    border-radius: 0 !important;
    text-decoration: none !important;
  }
  
  .nav-item {
    background-color: #e3e3e3;
    margin-top: 0 !important;
    border-bottom: 1px solid #b2b2b2;
    border-right: none;
  }
  
  .nav-item.active {
    border-right: none;
    padding-bottom: 1px;
  }
  
  .nav-link.active {
    background-color: white !important;
    color: black !important;
  }
  
  .panel-default {
    margin-bottom: 6px !important;
  }
  